import request from "../utils/request";
// 获取小区
export function calorimeters (data) {
    return request({
      method: 'get',
      url: 'calorimeter',
      params: data
    })
  }

// 获取热表抄表历史记录
export function calorimeterDataChangedget (data) {
  return request({
    method: 'GET',
    url: 'calorimeterDataChanged',
    params: data
  })
}

// 查询设置进程
export function calorimeterCommandget (data) {
  return request({
    method: 'GET',
    url: 'calorimeterCommand',
    params: data
  })
}

// 删除
export function calorimeterdele (data) {
  return request({
    method: 'delete',
    url: 'calorimeter',
    data
  })
}

// 获取单条添加型号接口
export function calorimeterTypeget (data) {
  return request({
    method: 'GET',
    url: 'calorimeterType',
    params: data
  })
}

// 添加热表信息
export function calorimeterpost (data) {
  return request({
    method: 'post',
    url: 'calorimeter',
    data
  })
}

// 添加热表信息
export function calorimeterput (data) {
  return request({
    method: 'put',
    url: 'calorimeter',
    data
  })
}

// 设置热表命令
export function calorimeterCommandpost (data) {
  return request({
    method: 'post',
    url: 'calorimeterCommand',
    data
  })
}