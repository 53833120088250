<template>
    <div class="T">
      <!-- 搜索 -->
      <div class="suso">
        <el-form>
          <el-form-item>
            <el-input placeholder="请搜索关键字点击输入进行查询" v-model.trim="cxyts.search_content" ></el-input>
          </el-form-item>
          <el-form-item label="小区:" class="elform">
              <el-select v-model="cxyts.community_id" placeholder="请选择小区" size="small"
              @change="selectQuarters(cxyts.community_id)">
                <el-option
                  v-for="item in quarters"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="楼栋:" class="elform">
              <el-select v-model="cxyts.building_id" placeholder="请选择楼栋" size="small"
              @change="selectBuildings(cxyts.building_id)">
                <el-option
                  v-for="item in Buildings"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="单元:" class="elform">
              <el-select v-model="cxyts.unit_id" placeholder="请选择单元" size="small"
              @change="selectunit(cxyts.unit_id)">
                <el-option
                  v-for="item in units"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="房间:" class="elform">
              <el-select v-model="cxyts.room_id" placeholder="请选择房间" size="small"
              @change="selectroom(cxyts.room_id)">
                <el-option
                  v-for="item in roomsfas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-button class="elfomobtn" type="primary" @click="pay_search" size="small">查 询</el-button>
            <el-button class="elfomobtn" type="primary" @click="Refreshbtn" size="small">刷 新</el-button>
          </el-form-item>
        </el-form>
        <div class="anbtn">
          <el-button @click="dialogwate">单条添加</el-button>
          <el-button @click="dialogpiliang = true">批量添加</el-button>
          <el-button @click="facomse">读当前计量数据</el-button>
          <el-button @click="dialogfabszjsia=true">写标准时间</el-button>
          <el-button @click="BatchExport">批量导出</el-button>
          <el-button @click="deletseletabo">删除选中的表</el-button>
        </div>
      </div>
      <!-- 单条添加 -->
<div class="locationOpenvalve">
    <el-dialog title="单条添加" :visible.sync="dialogte" width="20%">
      <div class="spans"/>
      <el-form>
        <el-form-item>
          <WaterAdd :ifMaterialEdit="ifMaterialEdit"/>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
  <!-- 批量导入 -->
<div class="locationOpenvalve">
    <el-dialog title="批量添加" :visible.sync="dialogpiliang">
      <div class="spans"/>
      <el-upload
        class="upload-demo"
        drag
        action="https://bg.calorimeter.sdbina.com/api/v1/calorimeterBulkImport"
        multiple
        :limit='1'
        :data='uploadHeaders'
        accept='.xls,.xlsx'
        :on-success='handleAvatarSuccess'
        :on-error='shibanerro'
        :on-change='hendleAvatarPreview'
        :on-remove='handleRemove'
        >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件,，，，，</div>
      </el-upload>
      <div @click="wjianbtn" style="color: #744dfe;">点击下载模板文件</div>
</el-dialog>
</div>
  <!-- 读当前计量数据 -->
  <div class="locatijilangshuju">
    <el-dialog title="读当前计量数据" :visible.sync="dialogfacoms">
      <div>是否对勾然的表具发送此分令</div>
      <el-button @click="fayesi">是</el-button>
      <el-button @click="dialogfacoms=false">否</el-button>
      <div class="testcolo">注：无采集器的表具不可下发命令</div>
</el-dialog>
</div>
<!-- 写标准时间 -->
<div class="locatijilangshuju">
    <el-dialog title="写标准时间" :visible.sync="dialogfabszjsia">
      <div class="block">
    <el-date-picker
      v-model="Faiste.d2"
      type="datetime"
      placeholder="选择日期时间"
      value-format="yyyyMMddHHmmss">
    </el-date-picker>
  </div>
  <el-button @click="fasiJbtn">确定</el-button>
  <div class="testcolo">注：无采集器的表具不可下发命令</div>
</el-dialog>
</div>
<!-- 提示报错的表号 -->
<div class="infinite-list-wrapper" style="overflow:auto" v-if="solos">
  <div style="display: flex;justify-content: space-between;align-items: center;padding: 10px;">
    <div>失败的表有{{counts}}个：</div>
    <el-button size="mini" @click="solos = false">关闭</el-button>
  </div>
    <ul class="list">
      <li v-for="i in count" :key="i" class="list-item">表号: {{ i }}</li>
    </ul>
  </div>
      <!-- 内容区域pc端 -->
      <div class="tab">
        <el-card>
          <el-table
          ref="table"
          :data="parData"
          tooltip-effect="dark"
          style="width: 100%"
          height='500'
          highlight-current-row
          @selection-change="handleSelectionChange"
          :header-cell-style = "{background:'#df6124'}">
          <el-table-column
          type="selection"
          width="55">
          </el-table-column>
          <el-table-column
          prop="pay_id"
          label="缴费编号"
          width="100">
          </el-table-column>
          <el-table-column
          prop="username"
          label="用户名"
          width="150">
          </el-table-column>
          <el-table-column
          prop="phone"
          label="手机号"
          width="140">
          </el-table-column>
          <el-table-column
          prop="meter_id"
          label="表号"
          width="150"
          :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
          prop="address"
          label="地址"
          width="260"
          :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
          prop="device_id"
          label="采集器ID"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="current_cooling_capacity"
          label="当前冷量"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="current_heat"
          label="当前热量"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="thermal_power"
          label="热功率"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="instantaneous_flow"
          label="瞬时流量"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="cumulative_flow"
          label="累计流量"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="water_supply_temperature"
          label="供水温度"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="return_water_temperature"
          label="回水温度"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="cumulative_working_time"
          label="累计工作时间"
          width="150">
          </el-table-column>
          <el-table-column
          prop="real_time"
          label="实时时间"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="temperature_sensor_status"
          label="温度传感器状态"
          width="150">
          </el-table-column>
          <el-table-column
          prop="air_traffic_control_failure"
          label="空管故障"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="battery_voltage"
          label="电池电压"
          show-overflow-tooltip>
          </el-table-column>
          <el-table-column
          prop="plot_receive_time"
          label="上报时服务记录的时间"
          width="200">
          </el-table-column>
          <el-table-column
        fixed="right"
        label="操作"
        width="380">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="cxunbtn(scope.row)">
            查询设置进程
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="history(scope.row)">
            查询历史记录
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="xiugai(scope.row)">
            修改
          </el-button>
          <el-button
            type="text"
            size="small"
            @click="schu(scope.row)"
            >
            删除
          </el-button>
        </template>
      </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="famlis.page_num"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="famlis.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
        </el-card>
      </div>
    <!-- 查询设置进程 -->
    <div class="cyclKai">
    <el-dialog title="查询进程" :visible.sync="dialgVsibles">
      <div class="wwoop"><span>设备编号: {{this.ccnn}}</span></div>
       <el-table
      :data="bansdata"
      border
      height='400'
      :header-cell-style="{'text-align':'center'}"
      :cell-style="{'text-align':'center'}"
      style="width: 100%">
      <el-table-column
        prop="id"
        label="序号"
        width="70">
      </el-table-column>
      <el-table-column
        prop="device_id"
        label="采集器ID">
      </el-table-column>
      <el-table-column
        prop="meter_id"
        label="表具编号"
        width="120">
      </el-table-column>
      <el-table-column
        prop="operating_command"
        label="操作命令">
      </el-table-column>
      <el-table-column
        prop="command_parameter"
        label="命令参数">
      </el-table-column>
      <el-table-column
        prop="sendcode"
        label="消息状态">
      </el-table-column>
      <el-table-column
        prop="creation_time"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="transmitting_time"
        label="发送时间">
      </el-table-column>
      <el-table-column
        prop="finish_time"
        label="完成时间">
      </el-table-column>
      <el-table-column
        prop="respondcode"
        label="响应结果">
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="processSizeChange"
        @current-change="processCurrentChange"
        :current-page="cuanss.page_num"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="cuanss.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="cuansstotal">
      </el-pagination>
      </el-dialog>
    </div>
      <!-- 编辑的弹框 历史纪录-->
      <div class="cyclKai">
    <el-dialog title="历史纪录" :visible.sync="historyVsibles">
      <div class="aaaaaaaaaa">
      <el-table :data="tableData" size=mini height='300' tooltip-effect="dark" highlight-current-row :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" element-loading-text="拼命加载中">
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column property="device_id" label="采集器ID" width="100" />
      <el-table-column property="current_cooling_capacity" label="当前冷量" width="100" :show-overflow-tooltip="true" />
      <el-table-column property="current_heat" label="当前热量" width="100" />
      <el-table-column property="thermal_power" label="热功率" width="100" :show-overflow-tooltip="true" />
      <el-table-column property="instantaneous_flow" label="瞬时流量" width="100" />
      <el-table-column property="cumulative_flow" label="累计流量" width="100" />
      <el-table-column property="water_supply_temperature" label="供水温度" width="100" />
      <el-table-column property="return_water_temperature" label="回水温度" width="100" />
      <el-table-column property="cumulative_working_time" label="累计工作时间" width="100" />
      <el-table-column property="real_time" label="实时时间" width="150" />
      <el-table-column property="temperature_sensor_status" label="温度传感器状态" width="120" />
      <el-table-column property="air_traffic_control_failure" label="空管故障" width="100" />
      <el-table-column property="battery_voltage" label="电池电压" width="172" />
      <el-table-column property="plot_receive_time" label="上报时服务记录的时间" width="172" />
      </el-table>
      <el-pagination
        @size-change="watefamlsSizeChange"
        @current-change="watefamlsCurrentChange"
        :current-page="watefamls.page_num"
        :page-sizes="[10, 20, 40, 60, 70]"
        :page-size="watefamls.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="watefamlstotal">
      </el-pagination>
      </div>
      </el-dialog>
    </div>
      <!-- 修改房间弹框 -->
  <div class="locationAdd">
  <el-dialog title="信息修改" :visible.sync="roomfication">
  <el-form>
    <el-form-item>
    <WaterAdd :fa-msg="newData" :disabled="disabled" :ifMaterialEdit="ifMaterialEdit"/>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
  </div>
    </el-dialog>
  </div>
   <!-- 编辑的弹框 批量导入消息提示-->
   <div class="cyclKai">
    <el-dialog title="消息提示" :visible.sync="ImortPrompt">
      <div><span>失败的有:{{error_count}}条</span><span style="margin-left: 50px;">成功的有:{{success_count}}条</span></div>
      <div class="aaaaaaaaaa">
      <el-table :data="error_list" size=mini height='300' :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}" element-loading-text="拼命加载中">
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column property="content" label="错误提示" width="660" :show-overflow-tooltip="true" />
      </el-table>
      <!-- <el-pagination
        @size-change="watefamlsSizeChange"
        @current-change="watefamlsCurrentChange"
        :current-page="watefamls.page_num"
        :page-sizes="[10, 20, 40, 60, 70]"
        :page-size="watefamls.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="watefamlstotal">
      </el-pagination> -->
      </div>
      </el-dialog>
    </div>
      <!-- 暂无数据 -->
      <div class="wuju" v-show="zanwu">暂无数据</div>
    </div>
  </template>
  <script>
import { dataConversionUtil } from '@/utils/data2Excel'
import {communities, buildings, units, rooms,} from '../../api/userProfile' // 小区楼栋单元房间接口
import{ calorimeters, calorimeterDataChangedget, calorimeterCommandget, calorimeterdele, calorimeterCommandpost } from '../../api/waterMeter' // 热表接口
import WaterAdd from '../../components/archves.vue'
import Cookies from 'js-cookie'
  export default {
    components: {
      WaterAdd
    },
    data () {
      return {
        disabled:true,
        ImortPrompt:false,
        error_count:null,
        success_count:null,
        error_list:[],
        value1:'',
        tableData: [],// 历史记录
        dialogte: false, // 单条添加
        dialogpiliang: false, // 批量添加
        dialogfacoms: false, // 读当前计量数据
        dialogfabszjsia: false, // 写标准时间
        historyVsibles: false,// 查询历史记录
        dialgVsibles: false,// 查询设置进程
        roomfication: false, // 修改
        bansdata: [],// 设置进程
        // 暂无数据
        zanwu: false,
        /**
         * 上报错的表号提示弹框
         **/
        solos: false,
        count: [],
        counts: 0,
        loading: false,
        // 主体数据
        parData: [{
          address: null,
          balance: null,
          meter_id: null,
          meter_status: null,
          meter_type: null,
          name: null,
          pay_id: null,
          phone: null,
          water_type: null
        }],
        // 主体数据参数
        famlis: {
          token: null,
          page_size: 20,
          page_num: 1,
          search_content: null,
          community_id: null,
          building_id: null,
          unit_id: null,
          room_id: null
        },
        // 区域导出
        // 区域导出
      qudaoCans: {
        token: null,
        community_id: null,
        building_id: null,
        unit_id: null,
        room_id: null,
        page_size: null,
        meter_id_list:[],
        page_num: 1
      },
        total: null,
        cuansstotal: null,// 设置进程分页
        ScuiChui: { // 删除
          token: null,
          meter_id: null,
        },
        // 删除多个热表信息
        BatchDelete:{
          token: null,
          meter_id_list: null
        },
        uploadHeaders: { // 批量添加
        token: null
      },
        // 下拉框
        // 获取小区
        quarters: [{
          id: null,
          name: null
        }],
        // 获取楼栋
        Buildings: [{
          id: null,
          name: null
        }],
        // 获取单元
        units: [{
          id: null,
          name: null
        }],
        // 获取房间
        roomsfas: [{
          id: null,
          name: null
        }],
        // 小区参数
        comities: {
          token: null
        },
        munity: null,
        // 楼栋参数
        buings: {
          token: null,
          community_id: null
        },
        unis: null,
        // 单元参数
        unistfs: {
          token: null,
          building_id: null
        },
        rom: null,
        // 房间参数
        roomfs: {
          token: null,
          unit_id: null
        },
        cxyts: {
          project_id: null,
          token: null,
          pay_id: null,
          search_content: null,
          location_id: null,
          insufficient_balance: null,
          community_id: null,
          building_id: null,
          unit_id: null,
          room_id: null
        },
        ccnn: null,
        ccoo: null,
        fff: null,
        list: [],
        cuanss: {
          token: null,
          meter_id: null,
          page_size: 20,
          page_num: 1
        },
        rdais: {
          token: null,
          meter_id_list: [],
          day_date: null,
          month_date: null
        },
        watefamlstotal: null,
        watefamls: {
          meter_id: null,
          token: null,
          // 当前的页数
          page_num: 1,
          // 当前每页显示多少条数据
          page_size: 10
        },
        ids: [],// 读当前计量数据
        iaitall:[],
        Faiste:{
          token: null,
          meter_id_list:[],
          d1:null,
          d2: null
        },
        newData:{}, //修改
        ifMaterialEdit: 0, // 0表示编辑，1表示新增
        lengths: 0 //选中
      }
    },
    created () {
      this.uploadHeaders.token = Cookies.get('token')
      this.userData()
      // 下拉框
      // 获取小区
      this.residential()
      // 获取楼栋
      this.Building()
      // 单元
      this.unit()
      // 房间
      this.room()
      this.szjc()
      this.lsjl()
    },
    methods: {
      // 主体数据
      userData () {
      this.famlis.token = Cookies.get('token')
      calorimeters(this.famlis).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, 'res')
            this.parData = res.data.data
            this.qudaoCans.page_size = res.data.count
            this.total = res.data.count
            if (this.total === 0) {
              this.zanwu = true
            }
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          if (res.data.message === '用户在别处登录') {
            this.$router.push('/', this.fasinull)
          }
        })
      },
      // 删除选中的表
      deletseletabo(){
        console.log(this.BatchDelete)
        this.BatchDelete.token = Cookies.get('token')
        this.$confirm(`此操作将永久删除选中该表信息, 共选中${this.lengths}条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        calorimeterdele(this.BatchDelete).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.userData()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      },
       // 区域导出
       BatchExport () {
      this.qudaoCans.token = Cookies.get('token')
      calorimeters(this.qudaoCans).then((res) => {
        if (res.data.status === 'success') {
          console.log(res, '热量档案导出')
          var data = res.data.data
          var tableHeader = [
            ['序号', '缴费编号', '用户名', '手机号', '表号', '地址', '采集器ID', '当前冷量', '当前热量', '热功率', '瞬时流量', '累计流量', '供水温度', '回水温度', '累计工作时间', '实时时间', '温度传感器状态', '空管故障', '电池电压', '上报时服务记录的时间']
          ]
          var dataList = []
          data.forEach((item, index) => {
            dataList.push([index + 1, item.pay_id, item.username, item.phone, item.meter_id, item.address, item.device_id, item.current_cooling_capacity, item.current_heat, item.thermal_power, item.instantaneous_flow, item.cumulative_flow, item.water_supply_temperature, item.return_water_temperature, item.cumulative_working_time, item.real_time, item.temperature_sensor_status, item.air_traffic_control_failure, item.battery_voltage, item.plot_receive_time])
          })
          dataConversionUtil.dataToExcel('热量档案导出', tableHeader, dataList)
          this.$message.success('导出成功!')
        } else if (res.data.status === 'error') {
          this.$message.error(res.data.message)
        }
      })
    },
      
      // table选择
      handleSelectionChange (val) {
        // this.ids = val

        // this.ids.forEach(item => this.iaitall.push(item.meter_id))
        // console.log(this.iaitall, '获取数组中的表号')

        // this.Faiste.meter_id_list = Array.from(new Set(this.iaitall));
        // console.log(this.Faiste.meter_id_list, '去重+赋值')

        // this.qudaoCans.meter_id_list = this.Faiste.meter_id_list.join()
        // console.log(this.qudaoCans.meter_id_list)
        this.Faiste.meter_id_list = val.map(item => item.meter_id)
        this.BatchDelete.meter_id_list = val.map(item => item.meter_id) // 批量删除
        console.log(this.Faiste.meter_id_list)
        this.lengths = this.Faiste.meter_id_list.length
      },
      // 查询历史纪录
      history (row) {
        console.log(row)
        this.ccoo = row.meter_id
        this.historyVsibles = true
        this.watefamls.token = Cookies.get('token')
        this.watefamls.meter_id = this.ccoo
        calorimeterDataChangedget(this.watefamls).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '历史记录')
            this.tableData = res.data.data
            this.watefamlstotal = res.data.count
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
        })
      },
      lsjl(){
        this.watefamls.token = Cookies.get('token')
        calorimeterDataChangedget(this.watefamls).then((res) => {
            console.log(res, '历史记录')
            this.tableData = res.data.data
            this.watefamlstotal = res.data.count
        })
      },
      // 单条添加
      dialogwate(){
        this.ifMaterialEdit = 1
        this.dialogte = true
      },
      // 批量添加
      // ----------
      // 文件上传失败
    shibanerro (res) {
      console.log(res, '文件上传失败')
    },
    // 下载导入模板
    wjianbtn () {
      window.location.href = 'https://calorimeter.sdbina.com/statics/example.xlsx'
    },
    // 导入文件上传成功时的钩子
    handleAvatarSuccess (res) {
      // console.log(res, '文件上传成功时的钩子')
      // this.$message(res.data, '这是一条消息提示')
      this.dialoBatch = true
      this.bansdata = res
      // this.error_list = this.bansdata.error_list
      console.log(this.bansdata, '123')
      this.error_count = this.bansdata.data.error_count
      this.success_count = this.bansdata.data.success_count
      this.error_list = this.bansdata.data.error_list
      this.ImortPrompt = true
    },
    hendleAvatarPreview () {
      this.dialogpiliang = false
    },
    handleRemove (file, fileList) {
      console.log(file, fileList, 'file, fileList')
    },
    // --------------------
    // 读当前计量数据
    facomse(){
      this.dialogfacoms = true
    },
    fayesi(){
      this.Faiste.token = Cookies.get('token')
      this.Faiste.d1 = '901F'
      calorimeterCommandpost(this.Faiste).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, 'eee')
            this.$message.success(res.data.message)
            this.dialogfacoms = false
            console.log(res.data.error_meter_id_list.length, 'ttt')
            if (res.data.error_meter_id_list.length != 0) {
              console.log(123)
              this.solos = true
              this.counts = res.data.error_meter_id_list.length
              this.count = res.data.error_meter_id_list
            } else {
              console.log(456)
              this.solos = false
            }
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
        })
    },
    // 写标准时间
    fasiJbtn(){
      this.Faiste.token = Cookies.get('token')
      this.Faiste.d1 = 'A015'
      calorimeterCommandpost(this.Faiste).then((res) => {
          if (res.data.status === 'success') {
            console.log(res,'eee')
            this.$message.success(res.data.message)
            this.dialogfabszjsia = false
            if (res.data.error_meter_id_list.length != 0) {
              console.log(123)
              this.solos = true
              this.count = res.data.error_meter_id_list
              this.counts = res.data.error_meter_id_list.length
            } else {
              console.log(456)
              this.solos = false
            }
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
        })
    },
      // 修改
      xiugai(row){
        console.log(row)
        this.newData = row
        this.ifMaterialEdit = 0
        this.roomfication = true
      },
      // 删除
      schu(row){
        console.log(row, '删除数据')
      this.ScuiChui.token = Cookies.get('token')
      this.ScuiChui.meter_id = row.meter_id
      this.$confirm('此操作将永久删除该表信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        calorimeterdele(this.ScuiChui).then((res) => {
          console.log(res, 'sc')
          if (res.data.status === 'success') {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.userData()
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      },
      // 查询设置进程
      cxunbtn (row) {
        this.dialgVsibles = true
        console.log(row, 'row')
        this.cuanss.token = Cookies.get('token')
        this.ccnn = row.meter_id
        this.cuanss.meter_id = this.ccnn
        calorimeterCommandget(this.cuanss).then((res) => {
          if (res.data.status === 'success') {
            console.log(res, '查询设置进程')
            this.bansdata = res.data.data
            this.cuansstotal = res.data.count
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
            this.bansdata = null
          }
          if (res.data.message === '用户在别处登录') {
            this.$router.push('/', this.fasinull)
          }
        })
      },
      szjc(){
        this.cuanss.token = Cookies.get('token')
        calorimeterCommandget(this.cuanss).then((res) => {
            console.log(res, '查询设置进程')
            this.bansdata = res.data.data
            this.cuansstotal = res.data.count
        })
      },
      // 获取小区
      residential () {
        this.comities.token = Cookies.get('token')
        communities(this.comities).then((res) => {
          console.log(res.data.data, '获取小区')
          this.quarters = res.data.data
        })
      },
      selectQuarters (val) {
        console.log(val, 'val')
        this.munity = val
        this.qudaoCans.community_id = val
        console.log(this.munity, '获取楼栋的id')
        this.cxyts.building_id = null
        this.cxyts.unit_id = null
        this.cxyts.room_id = null
        this.Building()
        this.cxyts.token = Cookies.get('token')
        calorimeters(this.cxyts).then((res) => {
          console.log(res, '查询')
          this.parData = res.data.data
          this.total = res.data.count
          if (this.total === 0) {
            this.zanwu = true
          }
        })
      },
      // 获取楼栋
      Building () {
        this.buings.token = Cookies.get('token')
        this.buings.community_id = this.munity
       buildings(this.buings).then((res) => {
          console.log(res.data.data, '获取楼栋')
          this.Buildings = res.data.data
        })
      },
      selectBuildings (val) {
        console.log(val, 'val2')
        this.unis = val
        this.qudaoCans.building_id = val
        this.cxyts.unit_id = null
        this.cxyts.room_id = null
        this.unit()
        this.cxyts.token = Cookies.get('token')
        calorimeters(this.cxyts).then((res) => {
          console.log(res, '查询')
          this.parData = res.data.data
          this.total = res.data.count
          if (this.total === 0) {
            this.zanwu = true
          }
        })
      },
      // 单元
      unit () {
        this.unistfs.token = Cookies.get('token')
        this.unistfs.building_id = this.unis
        units(this.unistfs).then((res) => {
          console.log(res.data.data, '获取单元')
          this.units = res.data.data
        })
      },
      selectunit (val) {
        console.log(val, 'val3')
        this.rom = val
        this.qudaoCans.unit_id = val
        this.cxyts.room_id = null
        this.room()
        this.cxyts.token = Cookies.get('token')
        calorimeters(this.cxyts).then((res) => {
          console.log(res, '查询')
          this.parData = res.data.data
          this.total = res.data.count
          if (this.total === 0) {
            this.zanwu = true
          }
        })
      },
      // 房间
      room () {
        this.roomfs.token = Cookies.get('token')
        this.roomfs.unit_id = this.rom
        rooms(this.roomfs).then((res) => {
          console.log(res.data.data, '获取房间')
          this.roomsfas = res.data.data
        })
      },
      selectroom (val) {
        console.log(val, '房间id')
        this.qudaoCans.room_id = val
        this.cxyts.token = Cookies.get('token')
        calorimeters(this.cxyts).then((res) => {
          console.log(res, '查询')
          this.parData = res.data.data
          this.total = res.data.count
          if (this.total === 0) {
            this.zanwu = true
          }
        })
      },
      // 查询
      pay_search () {
        this.cxyts.token = Cookies.get('token')
        calorimeters(this.cxyts).then((res) => {
          console.log(res)
          console.log(res, '查询')
          this.parData = res.data.data
          this.total = res.data.count
        })
      },
      Refreshbtn(){
        location.reload()
      },
      // 监听 page_size 改变的事件
      handleSizeChange (newSize) {
        console.log(newSize, 'q')
        this.famlis.page_size = newSize
        this.famlis.search_content = this.cxyts.search_content
        this.famlis.community_id = this.cxyts.community_id
        this.famlis.building_id = this.cxyts.building_id
        this.famlis.unit_id = this.cxyts.unit_id
        this.famlis.room_id = this.cxyts.room_id
        this.userData()
      },
      // 监听 页码值 改变的事件
      handleCurrentChange (newPage) {
        console.log(newPage)
        this.famlis.page_num = newPage
        this.famlis.search_content = this.cxyts.search_content
        this.famlis.community_id = this.cxyts.community_id
        this.famlis.building_id = this.cxyts.building_id
        this.famlis.unit_id = this.cxyts.unit_id
        this.famlis.room_id = this.cxyts.room_id
        this.userData()
      },
      // 查询设置进程
      processSizeChange (newSize) {
        console.log(newSize, 'page_size第一')
        this.cuanss.page_size = newSize
        this.cuanss.meter_id = this.ccnn
        debugger
        this.szjc()
      },
      // 监听 页码值 改变的事件 查询设置进程
      processCurrentChange (newPage) {
        console.log(newPage,'page_num第二')
        this.cuanss.page_num = newPage
        this.cuanss.meter_id = this.ccnn
        this.szjc()
      },
      // 历史记录
      watefamlsSizeChange (newSize) {
        console.log(newSize, 'q')
        this.watefamls.page_size = newSize
        this.watefamls.meter_id = this.ccoo
        this.lsjl()
      },
      // 监听 页码值 改变的事件
      watefamlsCurrentChange (newPage) {
        console.log(newPage)
        this.watefamls.page_num = newPage
        this.watefamls.meter_id = this.ccoo
        this.lsjl()
      }
    }
  }
  
  </script>
  
  <style lang="less" scoped>
  /deep/.rowStyle{
        background-color: oldlace !important;
      }
  .T{
      padding: 0;
  }
  .testcolo{
    margin-top: 10px;
    color: #C0353A;
  }
  // 搜索
  .suso {
    .el-form {
      display: flex;
      flex-wrap: wrap;
      .el-input {
        width: 200px;
      }
      .el-button {
        margin-left: 10px;
        background-color: #df6124;
        border: 0px;
      }
      .el-form-item{
        margin-bottom: 9px;
        margin-left: 10px;
        display: flex;
      .el-select{
        width: 150px;
      }
      }
    }
    .anbtn{
      margin-left: 10px;
      margin-bottom: 10px;
      .el-button{
        background-color: #df6124;
        color: #fff;
      }
    }
  }
  .locatijilangshuju{
      ::v-deep .el-dialog{
        width: 20%;
        .el-button{
          margin-top: 10px;
          background-color: #df6124;
          color: #FFF;
          border: 1px solid #df6124;
        }
    }
  }
  .infinite-list-wrapper{
    width: 20%;
    height: 200px;
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 999;
    background: #fff;
    border: 1px #EBEBEB solid;
    border-radius: 10px;
    .list{
      width: 100%;
      padding: 0px 20px;
      .list-item{
        margin-top: 10px;
        background: #E8F3FE;
        line-height: 40px;
        display: flex;
        justify-content: center;
      }
    }
  }
  // 分页
  .feye{
    background-color: rgb(247, 245, 245);
    width: 1542px;
    height: 40px;
    position: fixed;
    right: 20px;
    bottom: 0;
  }
  // 暂无数据
  .wuju{
    margin-left: 40%;
    margin-top: 15%;
  }
  // <!-- table表格 -->
      .tab{
        //   padding: 30px 30px 0px 74px;
          // 分页
          .el-pagination{
            ::v-deep .el-pagination__rightwrapper{
              float: left;
              margin-left: 20px;
            }
          }
          .el-card{
            border-radius: 10px;
            ::v-deep .el-card__body{
              padding: 0px;
            }
            ::v-deep .el-table thead{
              color: #fff;
              font-weight: 300;
            }
           ::v-deep .cell{
             font-family:myFont;
            }
          }
          ::v-deep .cell{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            text-align: center;
          }
          .el-button{
            padding: 10px;
            border: 1px solid #df6124;
            background-color: #df6124;
            color: #FFF;
            letter-spacing: 2PX;
          }
      }
      // 滚动条大小设置
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  /*纵向滚动条*/
  width: 5px;
  /*横向滚动条*/
  height: 15px;
}
      // 查询进程
      .wwoop{
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        .el-icon-refresh-right{
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #928d8d;
          font-size: 16px;
        }
      }

    // 查询历史 查询设置进程
    .cyclKai{
      .el-dialog__wrapper{
      ::v-deep .el-dialog{
      width: 40%;
      .Periodic{
          display: flex;
          justify-content: space-between;
        }
        .time{
          display: flex;
          align-items: center;
        }
        .block{
          display: flex;
          align-items: center;
          .el-select{
            width: 100px;
          }
        }
      }
    }
    }
    // 修改
    .locationAdd{
    .el-dialog__wrapper{
    ::v-deep .el-dialog{
    width: 20%;
    }
  }
  .el-button{
    border: 1px solid #C0353A;
    background-color: #C0353A;
  }
  }
  </style>
  