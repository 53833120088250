<template>
    <div>
      <!-- 解绑水表 -->
      <div class="numf">
        <el-form v-model="LadderData" label-width="80px">
          <el-form-item
            label="表号 : "
            prop="meter_id"
          >
            <el-input
              v-model.trim="LadderData.meter_id"
              size="small"
              placeholder="请输入表号"
            :disabled="disabled"
            ></el-input>
          </el-form-item>
          <el-form-item label="型号 : ">
            <el-select
              v-model="LadderData.meter_type_id"
              placeholder="请选择型号"
              clearable
              size="small"
            >
              <el-option
                v-for="item in meteData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="姓名:">
            <el-input
              v-model.trim="LadderData.username"
              placeholder="请输入姓名"
              size="small"
            ></el-input> </el-form-item
          >
          <el-form-item label="联系电话:">
            <el-input
              v-model.trim="LadderData.phone"
              placeholder="请输入电话"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件号码:">
            <el-input
              v-model.trim="LadderData.card_number"
              placeholder="请输入证件号码"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item>
          </el-form-item>
          <div class="ioxo">
            <el-form-item label="小区:" :required="true">
              <el-select
                v-model="LadderData.quarters_id"
                placeholder="请选择小区"
                size="small"
                @change="selectQuarters(LadderData.quarters_id)"
              >
                <el-option
                  v-for="item in quarters"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button size="mini" icon="el-icon-plus" @click="btnquars" />
          </div>
          <div class="ioxo">
            <el-form-item label="楼栋:" :required="true">
              <el-select
                v-model="LadderData.Buildings_id"
                placeholder="请选择楼栋"
                size="small"
                @change="selectBuildings(LadderData.Buildings_id)"
              >
                <el-option
                  v-for="item in Buildings"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button size="mini" icon="el-icon-plus" @click="btnBuils" />
          </div>
          <div class="ioxo">
            <el-form-item label="单元:" :required="true">
              <el-select
                v-model="LadderData.units_id"
                placeholder="请选择单元"
                size="small"
                @change="selectunit(LadderData.units_id)"
              >
                <el-option
                  v-for="item in units"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button size="mini" icon="el-icon-plus" @click="btnunits" />
          </div>
          <div class="ioxo">
            <el-form-item label="房间:" :required="true">
              <el-select
                v-model="LadderData.room_id"
                placeholder="请选择房间"
                size="small"
              >
                <el-option
                  v-for="item in rooms"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-button size="mini" icon="el-icon-plus" @click="btnrooms" />
          </div>
        </el-form>
          <div slot="footer" class="preservationBtn">
      <el-button type="primary" size="small" @click="waterLadder"> <i class="el-icon-document"/>保 存</el-button>
    </div>
      </div>
      <!-- 添加弹框 -->
      <div>
        <!-- 添加小区弹框 -->
        <div class="locationAdd">
          <el-dialog title="信息编辑" :visible.sync="dialogQuarters" :append-to-body='true' width="20%">
            <el-form>
              <el-form-item label="小区名称: " :label-width="formLabelWidth">
                <el-input
                  autocomplete="off"
                  v-model.trim="addition.name"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" @click="btnQuarters"
                ><i class="el-icon-document" />保存</el-button
              >
            </div>
          </el-dialog>
        </div>
        <!-- 添加楼栋弹框 -->
        <div class="locationAdd">
          <el-dialog title="信息编辑" :visible.sync="dialogBuildings" :append-to-body='true' width="20%">
            <el-form>
              <el-form-item label="楼栋名称 : " :label-width="formLabelWidth">
                <el-input
                  autocomplete="off"
                  v-model.trim="addBuildings.name"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" @click="btnBuildings"
                ><i class="el-icon-document" />保存</el-button
              >
            </div>
          </el-dialog>
        </div>
        <!-- 添加单元弹框 -->
        <div class="locationAdd">
          <el-dialog title="信息编辑" :visible.sync="dialogUnits" :append-to-body='true' width="20%">
            <el-form>
              <el-form-item label="单元名称 : " :label-width="formLabelWidth">
                <el-input
                  autocomplete="off"
                  v-model.trim="adddialogUnits.name"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" @click="btndialogUnits"
                ><i class="el-icon-document" />保存</el-button
              >
            </div>
          </el-dialog>
        </div>
        <!-- 添加房间弹框 -->
        <div class="locationAdd">
          <el-dialog title="信息编辑" :visible.sync="dialogrooms" :append-to-body='true' width="20%">
            <el-form>
              <el-form-item label="房间名称 : " :label-width="formLabelWidth">
                <el-input
                  autocomplete="off"
                  v-model.trim="adddialogrooms.name"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" size="small" class="boucuis" @click="btndialogrooms"
                ><i class="el-icon-document" />保存</el-button
              >
            </div>
          </el-dialog>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // 小区楼栋单元房间的数据，小区添加，楼栋添加，单元添加，房间添加
  import {communities, buildings, units, rooms, putcommunities, putbuildings, putunits, putrooms} from '../api/userProfile'
  import {calorimeterTypeget, calorimeterpost, calorimeterput} from'../api/waterMeter' // 热表接口 （单条添加型号，添加, 修改）
  import Cookies from 'js-cookie'
  export default {
    props: {
    faMsg: {
      type: Object,
      default: () => {}
    },
    ifMaterialEdit: null,
    disabled: null
  },
  watch: {
    faMsg: {
      handler (val) {
        if (!val) return
        this.LadderData = val
        console.log(this.LadderData)
        // this.LadderData.room_id = null
      },
      deep: true,
      immediate: true
    }
  },
    data () {
      return {
        formLabelWidth: '90px',
        userFile: {
          name: null,
          project_id: null,
          token: null,
          address: null,
          phone: null,
          location_id: null,
          meter_id: null,
          Buildings_id: null,
          room_id: null
        },
        value: null,
        // 下拉框
        // 获取小区
        dialogQuarters: false,
        quarters: [
          {
            id: null,
            name: null
          }
        ],
        // 获取楼栋
        dialogBuildings: false,
        Buildings: [
          {
            id: null,
            name: null
          }
        ],
        // 获取单元
        dialogUnits: false,
        units: [
          {
            id: null,
            name: null
          }
        ],
        // 获取房间
        dialogrooms: false,
        rooms: [
          {
            id: null,
            name: null
          }
        ],
        // 添加小区
        addition: {
          token: null,
          name: null
        },
        // 添加楼栋
        addBuildings: {
          token: null,
          community_id: null,
          name: null
        },
        // 添加单元
        adddialogUnits: {
          token: null,
          building_id: null,
          name: null
        },
        // 添加房间
        adddialogrooms: {
          token: null,
          unit_id: null,
          name: null
        },
  
        // 小区参数
        comities: {
          token: null
        },
        munity: null,
        // 楼栋参数
        buings: {
          token: null,
          community_id: null
        },
        unis: null,
        // 单元参数
        unistfs: {
          token: null,
          building_id: null
        },
        rom: null,
        // 房间参数
        roomfs: {
          token: null,
          unit_id: null
        },
        // 添加
        LadderData: {
          token: null,
          meter_type_id: null,
          room_id: null,
          meter_id: null,
          username: null,
          phone: null,
          card_number: null
        },
        // 型号下拉框
        meteData: [
          {
            id: null,
            name: null
          }
        ],
        // 水表状态下拉框
        famlis: {
          project_id: null,
          token: null
        },
      }
    },
    created () {
      this.userFile.token = Cookies.get('token')
      // 获取小区
      this.residential()
      // 获取楼栋
      this.Building()
      // 单元
      this.unit()
      // 房间
      this.room()
      this.basData()
    },
    methods: {
      // 获取型号数据
      basData () {
        this.famlis.token = Cookies.get('token')
        calorimeterTypeget(this.famlis).then((res) => {
          console.log(res)
          if (res.data.status === 'success') {
            this.meteData = res.data.data
          } else if (res.data.status === 'error') { this.$message.error(res.data.message) }
        })
      },
      // 添加
      waterLadder () {
        console.log(this.ifMaterialEdit)
      this.LadderData.token = Cookies.get('token')
      if(this.ifMaterialEdit === 1){
        calorimeterpost(this.LadderData).then((res) => {
          console.log(res, '添加')
          if (res.data.status === 'success') {
            this.$notify({
              title: '成功',
              message: '添加成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
        })
        this.dialogwate = false
      }else if (this.ifMaterialEdit === 0){
      // 编辑接口对接
      calorimeterput(this.LadderData).then((res) => {
      if (res.data.status === 'success') {
      this.tableData = res.data.data
      this.$message.success('编辑成功')
      } else {
      this.$message.error(res.data.message)
      }
      })
      }
//-----------------------------------
      },
      // -----------------------------------------------------------------------------
      // 获取小区
      residential () {
        this.comities.token = Cookies.get('token')
        communities(this.comities).then((res) => {
          console.log(res.data.data, '获取小区')
          this.quarters = res.data.data
        })
      },
      selectQuarters (val) {
        console.log(val, 'val')
        this.munity = val
        this.userFile.Buildings_id = null
        this.userFile.room_id = null
        this.userFile.units_id = null
        this.Building()
        this.room()
        this.unit()
      },
      // 获取楼栋
      Building () {
        this.buings.token = Cookies.get('token')
        this.buings.community_id = this.munity
        buildings(this.buings).then((res) => {
          console.log(res.data.data, '获取楼栋')
          this.Buildings = res.data.data
        })
      },
      selectBuildings (val) {
        console.log(val, 'val2')
        this.unis = val
        this.userFile.room_id = null
        this.userFile.units_id = null
        this.unit()
        this.room()
      },
      // 单元
      unit () {
        this.unistfs.token = Cookies.get('token')
        this.unistfs.building_id = this.unis
        units(this.unistfs).then((res) => {
          console.log(res.data.data, '获取单元')
          this.units = res.data.data
        })
      },
      selectunit (val) {
        console.log(val, 'val3')
        this.rom = val
        this.userFile.room_id = null
        this.room()
      },
      // 房间
      room () {
        this.roomfs.token = Cookies.get('token')
        this.roomfs.unit_id = this.rom
        rooms(this.roomfs).then((res) => {
          console.log(res.data.data, '获取房间')
          this.rooms = res.data.data
        })
      },
      // 添加小区按钮
      btnquars () {
        this.dialogQuarters = true
      },
      // 添加小区保存按钮
      btnQuarters () {
        this.addition.token = Cookies.get('token')
        putcommunities(this.addition).then((res) => {
          console.log(res, '添加小区')
          if (res.data.status === 'success') {
            this.residential()
            this.$notify({
              title: '成功',
              message: '添加小区成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          this.dialogQuarters = false
          this.addition.name = null
        })
      },
      // 添加楼栋按钮
      btnBuils () {
        this.dialogBuildings = true
        console.log('楼栋添加')
      },
      // 添加楼栋保存按钮
      btnBuildings () {
        this.addBuildings.token = Cookies.get('token')
        this.addBuildings.community_id = this.munity
        putbuildings(this.addBuildings).then((res) => {
          console.log(res, '添加楼栋')
          if (res.data.status === 'success') {
            this.Building()
            this.$notify({
              title: '成功',
              message: '添加楼栋成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          this.dialogBuildings = false
          this.addBuildings.name = null
        })
      },
      // 添加单元按钮
      btnunits () {
        this.dialogUnits = true
      },
      // 添加单元保存按钮
      btndialogUnits () {
        this.adddialogUnits.token = Cookies.get('token')
        this.adddialogUnits.building_id = this.unis
        putunits(this.adddialogUnits).then((res) => {
          console.log(res, '添加单元')
          if (res.data.status === 'success') {
            this.unit()
            this.$notify({
              title: '成功',
              message: '添加单元成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          this.dialogUnits = false
          this.adddialogUnits.name = null
        })
      },
      // 添加房间按钮
      btnrooms () {
        this.dialogrooms = true
      },
      // 添加房间保存按钮
      btndialogrooms () {
        this.adddialogrooms.token = Cookies.get('token')
        this.adddialogrooms.unit_id = this.rom
        putrooms(this.adddialogrooms).then((res) => {
          console.log(res, '添加房间')
          if (res.data.status === 'success') {
            this.room()
            this.$notify({
              title: '成功',
              message: '添加楼房间成功',
              type: 'success'
            })
          } else if (res.data.status === 'error') {
            this.$message.error(res.data.message)
          }
          this.dialogrooms = false
          this.adddialogrooms.name = null
        })
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .el-input{
    width: 215px;
  }
  .ioxo{
    display: flex;
    .el-button {
      margin-top: 4px;
      margin-left: 16px;
      background-color: #df6124;
      height: 35px;
      color: #fff;
      }
  }
  .dialog-footer{
    .el-button{
      background-color: #df6124;
    border: 1px solid #df6124;
    }
  }
  .preservationBtn{
  .el-button{
    background-color: #df6124;
    border: 1px solid #df6124;
    color: #fff;
  }
  }
  </style>
  